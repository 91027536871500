import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';
import * as config from "../../config/constants";

const Businesses = () => {
  const [businessData, setBusinessData] = useState();
  const [businessesNext, setBusinessesNext] = useState('');
  const [businessesCount, setBusinessesCount] = useState(0);
  const [businessesDisplayCount, setBusinessesDisplayCount] = useState(0);
  const [businessesUnderControlCount, setBusinessesUnderControlCount] = useState({});
  const [businessesLoading, setBusinessesLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const numRecordPage = config.BusinessPageSize;

  const getBusinessPercentage = async (activeBusinessId) => {
    try {
      const { data } = await store.api.getBusinessPercentage(activeBusinessId);
      return data;
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    }
  };

  const getBusinesses = async () => {
    try {
      setIsLoading(true);
      const { data } = await store.api.getBusinesses(numRecordPage);
      setBusinessData(data.results);
      setBusinessesNext(data.next);
      setBusinessesCount(data.count);
      data.count > numRecordPage ? setBusinessesDisplayCount(numRecordPage) : setBusinessesDisplayCount(data.count);
      var dict ={};
      const promises = data.results.map(async (companyBusiness, i) => {
         dict[companyBusiness.id] =  await getBusinessPercentage(companyBusiness.id);
      });
      await Promise.all(promises);
      setBusinessesUnderControlCount(dict);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getNextBusinesses = async () => {
    try {
      setBusinessesLoading(true);
      const { data } = await store.api.getNextDataWithURL(businessesNext);
      let addNewDomains = businessData;
      setBusinessesNext(data.next);
      setBusinessData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        addNewDomains.push(process);
        if (arrayLength == i + 1) {
          setBusinessData(addNewDomains);

          setBusinessesDisplayCount(businessesDisplayCount + arrayLength);
        }
      });
      var dict = businessesUnderControlCount;
      const promises = data.results.map(async (business, i) => {
         dict[business.id] =  await getBusinessPercentage(business.id);
      });
      await Promise.all(promises);
      setBusinessesUnderControlCount(dict);
      setBusinessesLoading(false);
    } catch (error) {
      setBusinessesLoading(false);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setBusinessesLoading(false);
    }
  };

  useEffect(() => {
    getBusinesses();
  }, []);

  const navigateToBusiness = (businessId) => {
    const path = '/business?business=' + businessId;
    history.push(path);
  };

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/dashboard')}>
        Home
      </div>
      <div className="header-icon"></div>
      <h4>Businesses</h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <span>
            Displaying {businessesDisplayCount} of {businessesCount} Businesses
          </span>
        </li>

        {businessData !== undefined &&
          businessData.map((companyBusiness, i) => {
            const businessId = companyBusiness.id;
            const percentage = businessesUnderControlCount[companyBusiness.id] ? businessesUnderControlCount[companyBusiness.id].percentage : 0;
            const total = businessesUnderControlCount[companyBusiness.id] ? businessesUnderControlCount[companyBusiness.id].total: 0;
            const under_control = businessesUnderControlCount[companyBusiness.id] ? businessesUnderControlCount[companyBusiness.id].under_control: 0;
            return (
              <li key={i} onClick={() => navigateToBusiness(businessId)}>
                <span>{businessId}</span>
                <span className="title-spacing">{companyBusiness.business.name}</span>
                <div className="status-text">
                  {/*<span className="last-updated">*/}
                  {/*  LAST UPDATED BY {companyBusiness?.last_updated_by.username} AT{' '}*/}
                  {/*  {companyBusiness?.updated_on}*/}
                  {/*</span>*/}
                  {/*<span className="owner">*/}
                  {/*  <b>{companyBusiness?.business?.owner?.username}</b> Owner*/}
                  {/*</span>*/}
                  {/* <span className="owner">Owner: {companyBusiness?.owner?.username}</span>*/}
                   <span className="owner"> Owner: {companyBusiness.owner.first_name} {companyBusiness.owner.last_name}</span>
                </div>
                {/*<span*/}
                {/*  className={`applicable-status ${*/}
                {/*    companyBusiness.applicable === 'Applicable' ? '' : 'not-applicable'*/}
                {/*  }`}*/}
                {/*>*/}
                {/*  {companyBusiness.applicable}*/}
                {/*/!*</span>*!/*/}

                <div className="applicable-percentage">   {under_control}/{total}   </div>
                <div className="status red-percentage">
                  <div
                  className="green"
                  style={{ width: `${percentage}%` }}>
                  </div>

                </div>
              </li>
            );
          })}
        {businessesCount > businessesDisplayCount && (
          <li className="get-next" key="next" onClick={() => getNextBusinesses()}>
            <span>{businessesLoading ? 'Loading...' : 'Load '+ numRecordPage.toString() +' more'}</span>
          </li>
        )}
      </ul>
    </section>
  );
};

Businesses.defaultProps = {};

export default Businesses;
