import 'assets/styles/compliance.scss';

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';
import * as config from "../../config/constants";

const BusinessFunctions = () => {
  const [businessFunctionsData, setBusinessFunctionsData] = useState();
  const [businessFunctionsNext, setBusinessFunctionsNext] = useState('');
  const [businessFunctionsCount, setBusinessFunctionsCount] = useState(0);
  const [businessFunctionsDisplayCount, setBusinessFunctionsDisplayCount] = useState(0);
  const [businessFunctionsLoading, setBusinessFunctionsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const numRecordPage = config.BusinessPageSize;

  const getBusinessFunctions = async () => {
    try {
      setIsLoading(true);
      const { data } = await store.api.getBusinessFunctions();
      setBusinessFunctionsData(data.results);
      setBusinessFunctionsNext(data.next);
      setBusinessFunctionsCount(data.count);
      data.count > numRecordPage
        ? setBusinessFunctionsDisplayCount(numRecordPage)
        : setBusinessFunctionsDisplayCount(data.count);
    } catch (error) {
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getNextBusinessFunctions = async () => {
    try {
      setBusinessFunctionsLoading(true);
      const { data } = await store.api.getNextDataWithURL(businessFunctionsNext);
      let addNewDomains = businessFunctionsData;
      setBusinessFunctionsNext(data.next);
      setBusinessFunctionsData(undefined);
      const arrayLength = data.results.length;
      data.results.map((process, i) => {
        addNewDomains.push(process);
        if (arrayLength == i + 1) {
          setBusinessFunctionsData(addNewDomains);
          setBusinessFunctionsLoading(false);
          setBusinessFunctionsDisplayCount(businessFunctionsDisplayCount + arrayLength);
        }
      });
    } catch (error) {
      setBusinessFunctionsLoading(false);
      if (error.response && error?.response?.status === 403) {
        enqueueSnackbar('You do not have permission to view this data', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('An error occurred, please contact support', {
          variant: 'error',
        });
      }
    } finally {
      setBusinessFunctionsLoading(false);
    }
  };

  useEffect(() => {
    getBusinessFunctions();
  }, []);

  const navigateToBusinessFunction = (businessFunctionId) => {
    const path = '/business-function?business-function=' + businessFunctionId;
    history.push(path);
  };

  return (
    <section className={'compliance-container'}>
      <div className="back-nav" onClick={() => history.push('/home')}>
        Home
      </div>
      <div className="header-icon"></div>
      <h4>Business Functions</h4>

      <ul className="categories-list">
        <li key="count" style={{ backgroundColor: '#f4f5f7' }}>
          <span>
            Displaying {businessFunctionsDisplayCount} of {businessFunctionsCount} Business
            Functions
          </span>
        </li>

        {businessFunctionsData !== undefined &&
          businessFunctionsData.map((businessFunction, i) => {
            const businessFunctionId = businessFunction.id;
            return (
              <li key={i} onClick={() => navigateToBusinessFunction(businessFunctionId)}>
                <span>{businessFunctionId}</span>
                <span className="title-spacing">{businessFunction.business_function.name}</span>
                <div className="status-text">
                  {/*<span className="last-updated">*/}
                  {/*  LAST UPDATED BY {businessFunction.last_updated_by.username} AT{' '}*/}
                  {/*  {businessFunction.updated_on}*/}
                  {/*</span>*/}
                  {/* <span className="owner">
                    <b>{businessFunction.business_function.entered_by}</b> Owner
                  </span> */}
                {/*<span className="owner">Owner: {businessFunction?.owner?.username}</span>*/}
                <span className="owner"> Owner: {businessFunction.owner.first_name} {businessFunction.owner.last_name}</span>
                </div>
                <span
                  className={`applicable-status ${
                    businessFunction.applicable === 'Applicable' ? '' : 'not-applicable'
                  }`}
                >
                  {businessFunction.applicable}
                </span>
              </li>
            );
          })}
        <li className="get-next" key="next" onClick={() => getNextBusinessFunctions()}>
          <span>{businessFunctionsLoading ? 'Loading...' :'Load '+ numRecordPage.toString() +' more'}</span>
        </li>
      </ul>
    </section>
  );
};

BusinessFunctions.defaultProps = {};

export default BusinessFunctions;
