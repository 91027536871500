import * as api from 'config/endpoints';

import httpAuthClient from 'services/httpAuthClient';
import httpClient from 'services/httpClient';
import {FILE_S3_SENT_PROCESS_API_ENDPOINT} from "config/endpoints";

const API = {
  // TODO - Not yet implemented - Start
  forgotPassword(payload) {
    return httpAuthClient.post(api.FORGOT_PASSWORD, payload);
  },
  confirmForgotPassword(payload) {
    return httpAuthClient.post(api.CONFIRM_FORGOT_PASSWORD, payload);
  },
  // TODO - Not yet implemented - End
  login(payload) {
    return httpClient.post(api.AUTH_LOGIN, payload);
  },
  refreshToken(payload) {
    return httpClient.post(api.AUTH_REFRESH_TOKEN, payload);
  },
  logout(payload) {
    return httpClient.post(api.AUTH_LOGOUT, payload);
  },
  getActiveUser() {
    return httpClient.get(api.ACTIVE_USER);
  },
  getLegalEntity(domain) {
    return httpClient.get(api.LEGAL_ENTITY, {domain: domain});
  },
  getComplianceFramework(id) {
    return httpClient.get(api.COMPLIANCE_FRAMEWORK, { requestParams: { id } });
  },
  getOperationalControlFramework(id) {
    return httpClient.get(api.OPERATIONAL_CONTROL_FRAMEWORK, { requestParams: { id } });
  },
  getBusinesses(pageSize) {
    return httpClient.get(api.BUSINESSES, {params: {page_size: pageSize}});
  },
  getBusiness(id,pageSize) {
    return httpClient.get(api.BUSINESS, {  requestParams: { id } ,
      params: {page_size: pageSize},
    });
  },

  getBusinessPercentage(id) {
    return httpClient.get(api.BUSINESS_PERCENTAGE, { requestParams: { id } });
  },
  getRegulationPercentage(id) {
    return httpClient.get(api.REGULATION_DOCUMENT_PERCENTAGE, { requestParams: { id } });
  },

  getBusinessProcessPercentage(id) {
    return httpClient.get(api.BUSINESS_PROCESS_PERCENTAGE, { requestParams: { id } });
  },


  getBusinessFunctionPercentage(id) {
    return httpClient.get(api.BUSINESS_FUNCTION_PERCENTAGE, { requestParams: { id } });
  },
  getBusinessFunctions() {
    return httpClient.get(api.BUSINESS_FUNCTIONS);
  },

  getFilesS3List(id) {
    return httpClient.get(api.FILE_S3_LIST_PROCESS_API_ENDPOINT, { requestParams: { id } });
  },
  getFilesS3ListRoot(id) {
    return httpClient.get(api.FILE_S3_LIST_ROOT_PROCESS_API_ENDPOINT, { requestParams: { id } });
  },
  sendFilesS3Sent(paload) {
    return httpClient.post(api.FILE_S3_SENT_PROCESS_API_ENDPOINT,paload);
  },
  filesS3Link(paload) {
    return httpClient.post(api.FILE_S3_LINK_FILE,paload);
  },
  getBusinessFunctionsForBusiness(companyBusiness, pageSize) {
    console.log('Getting getBusinessFunctionsForBusiness with ID: ' + companyBusiness);
    return httpClient.get(api.BUSINESS_FUNCTIONS, {
      params: { company_business: companyBusiness , page_size: pageSize},
    });
  },
  getBusinessFunction(id) {
    return httpClient.get(api.BUSINESS_FUNCTION, { requestParams: { id } });
  },
  getBusinessProcesses() {
    return httpClient.get(api.BUSINESS_PROCESSES);
  },
  getBusinessProcessesForBusinessFunction(companyBusinessFunction, pageSize) {
    return httpClient.get(api.BUSINESS_PROCESSES, {
      params: { company_business_function: companyBusinessFunction, page_size: pageSize },
    });
  },
  getDocumentRegulations(param) {
    if (param === 'regulation')
      return httpClient.get(api.DOCUMENT_REGULATIONS_REG);

    return httpClient.get(api.DOCUMENT_REGULATIONS_OPS);
  },

  getMicroProcessByRegulation(id) {
    return httpClient.get(api.MICRO_PROCESS_BY_REGULATION, { requestParams: { id } });
  },
  getBusinessProcess(id) {
    return httpClient.get(api.BUSINESS_PROCESS, { requestParams: { id } });
  },
  getMicroProcesses() {
    return httpClient.get(api.MICRO_PROCESSES);
  },
  getMicroProcessesReviewed() {
    return httpClient.get(api.MICRO_PROCESSES_REVIEWED);
  },
  getMicroProcessesReviewedBetween(start_date, end_date) {
    return httpClient.get(api.MICRO_PROCESSES_REVIEWED_BETWEEN, { requestParams: { start_date , end_date}});
  },
  getMicroProcessesSuggested() {
    return httpClient.get(api.MICRO_PROCESSES_SUGGESTED);
  },
  getMicroProcessesRead() {
    return httpClient.get(api.MICRO_PROCESSES_READ);
  },
  getMicroProcessesFailed() {
    return httpClient.get(api.MICRO_PROCESSES_FAILED);
  },


  getMicroProcessesForBusinessProcess(companyBusinessProcess) {
    return httpClient.get(api.MICRO_PROCESSES, {
      params: { company_business_process: companyBusinessProcess },
    });
  },
  getMicroProcess(id) {
    return httpClient.get(api.MICRO_PROCESS, { requestParams: { id } });
  },
  getComplianceDocumentValidationLogs(companyMicroRegulatoryProcess, complianceDocumentType) {
    return httpClient.get(api.COMPLIANCE_DOCUMENT_VALIDATION_LOGS, {
      params: {
        company_micro_regulatory_process: companyMicroRegulatoryProcess,
        compliance_document_type: complianceDocumentType,
      },
    });
  },
  getMicroProcessValidationLogs(companyMicroRegulatoryProcess) {
    return httpClient.get(api.MICRO_PROCESS_VALIDATION_LOGS, {
      params: {
        company_micro_regulatory_process: companyMicroRegulatoryProcess,
      },
    });
  },
  getMicroProcessValidationLogsForDocument(companyMicroRegulatoryProcess, complianceDocumentType) {
    return httpClient.get(api.MICRO_PROCESS_VALIDATION_LOGS, {
      params: {
        company_micro_regulatory_process: companyMicroRegulatoryProcess,
        compliance_document_type: complianceDocumentType,
      },
    });
  },
  // Risk
  getRisk(id) {
    return httpClient.get(api.RISK, { requestParams: { id } });
  },
  getRiskProcess(id) {
    return httpClient.get(api.RISK_PROCESS, { requestParams: { id } });
  },
  patchRiskProcess(id, payload) {
    return httpClient.patch(api.RISK_PROCESS, payload, { requestParams: { id } });
  },
  // Policy
  getPolicy(id) {
    return httpClient.get(api.POLICY, { requestParams: { id } });
  },
  getPolicyProcess(id) {
    return httpClient.get(api.POLICY_PROCESS, { requestParams: { id } });
  },
  patchPolicyProcess(id, payload) {
    return httpClient.patch(api.POLICY_PROCESS, payload, { requestParams: { id } });
  },
  // Procedure
  getProcedure(id) {
    return httpClient.get(api.PROCEDURE, { requestParams: { id } });
  },

  getS3File(name) {
    return httpClient.get(api.FILE_S3_PROCESS_API_ENDPOINT, { requestParams: { name } });
  },
  getProcedureProcess(id) {
    return httpClient.get(api.PROCEDURE_PROCESS, { requestParams: { id } });
  },
  patchProcedureProcess(id, payload) {
    return httpClient.patch(api.PROCEDURE_PROCESS, payload, { requestParams: { id } });
  },
  // Evidence
  getEvidence(id) {
    return httpClient.get(api.RISK, { requestParams: { id } });
  },
  getEvidenceProcess(id) {
    return httpClient.get(api.RISK_PROCESS, { requestParams: { id } });
  },
  patchEvidenceProcess(id, payload) {
    return httpClient.patch(api.EVIDENCE_PROCESS, payload, { requestParams: { id } });
  },
  // Control
  getControl(id) {
    return httpClient.get(api.CONTROL, { requestParams: { id } });
  },
  getControlProcess(id) {
    return httpClient.get(api.CONTROL_PROCESS, { requestParams: { id } });
  },
  patchControlProcess(id, payload) {
    return httpClient.patch(api.CONTROL_PROCESS, payload, { requestParams: { id } });
  },
  // Monitoring
  getMonitoring(id) {
    return httpClient.get(api.MONITORING, { requestParams: { id } });
  },
  getMonitoringProcess(id) {
    return httpClient.get(api.MONITORING_PROCESS, { requestParams: { id } });
  },
  patchMonitoringProcess(id, payload) {
    return httpClient.patch(api.MONITORING_PROCESS, payload, { requestParams: { id } });
  },
  getNextDataWithURL(url) {
    return httpClient.get(url);
  },
};

export default API;
